import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'src/components/Base'
import classNames from 'classnames'
import * as styles from './styles.module.scss'

const ReadMoreLink = ({ className, link, text, external, target }) => {
  const data = useStaticQuery(graphql`
    query NewsCardReadMoreQuery {
      allWp {
        nodes {
          themeGeneralSettings {
            atspOptions {
              readMoreText
            }
          }
        }
      }
    }
  `)

  const defaultReadMoreText =
    data.allWp.nodes[0].themeGeneralSettings.atspOptions.readMoreText

  const readMoreLinkClasses = classNames({
    [styles.readMoreLink]: true,
    [className]: !!className,
  })

  return (
    <>
      {!external && (
        <Link className={readMoreLinkClasses} to={link}>
          <span className={styles.pre}>»</span>
          <span className="link-text">{text || defaultReadMoreText}</span>
        </Link>
      )}

      {external && (
        <a
          className={readMoreLinkClasses}
          target={target || '_blank'}
          rel="noreferrer"
          href={link}
        >
          <span className={styles.pre}>»</span>
          <span className="link-text">{text || defaultReadMoreText}</span>
        </a>
      )}
    </>
  )
}

ReadMoreLink.PropTypes = {
  text: PropTypes.string,
  link: PropTypes.string.isRequired,
  external: PropTypes.boolean,
}

ReadMoreLink.defaultProps = {
  text: undefined,
  className: undefined,
  external: false,
}

export default ReadMoreLink
