import React from 'react'
import { Link } from 'gatsby'

import Seo from 'src/components/Seo'

import AustrianFlag from 'src/assets/images/at.inline.svg'

import { Heading, Section, DropdownButton } from 'src/components/Base'
import SliderTopBar from 'src/components/SliderTopBar'

const dropdownItems = [
  {
    value: 'myval1',
    label: 'mylabel1',
    icon: <AustrianFlag />,
  },
  {
    value: 'myval2',
    label: 'mylabel2',
    icon: <AustrianFlag />,
  },
  {
    value: 'myval3',
    label: 'mylabel3',
    icon: <AustrianFlag />,
  },
  {
    value: 'myval4',
    label: 'mylabel4',
    icon: <AustrianFlag />,
  },
]

const test = (item) => {
  //  console.log(item)
}

const ComponentsPage = () => (
  <>
    <Seo title="Page two" />
    <Section>
      <Heading as="h2" size={2} color="dark">
        SliderTopBar Component
      </Heading>
      <SliderTopBar
        heading="Heading"
        link="http://example.com"
        linkTitle="LinkTitle"
      />
    </Section>
    <Section>
      <h1>Hi from the second page</h1>
      <p>Welcome to page 2</p>
      <Link to="/">Go back to the homepage</Link>
    </Section>
    <Section>
      <DropdownButton
        onChange={test}
        color="secondary"
        dropdownItems={dropdownItems}
      >
        Hello World
      </DropdownButton>

      <Heading>Heading Light (default)</Heading>

      <Heading color="dark">Heading Dark</Heading>

      <Heading color="#00ff00">Heading hex</Heading>

      <Heading color="dark">Heading with *underline*</Heading>
    </Section>
  </>
)

export default ComponentsPage
