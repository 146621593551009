import React from 'react'
import PropTypes from 'prop-types'
import ReadMoreLink from 'src/components/ReadMoreLink'
import { cSliderTopBar, cSliderTopBarHeading } from './styles.module.scss'

const SliderTopBar = ({ heading, link, linkTitle }) => (
  <div className={cSliderTopBar}>
    <span className={cSliderTopBarHeading}>{heading}</span>
    <ReadMoreLink link={link} text={linkTitle} />
  </div>
)

SliderTopBar.propTypes = {
  heading: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkTitle: PropTypes.string.isRequired,
}

export default SliderTopBar
